/** @jsx jsx */
import { graphql } from "gatsby";
import { Box, Container, Heading, jsx } from "theme-ui";
import ContentWithImage from "../../components/ContentWithImage";
import HexagonList from "../../components/HexagonList";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const LINK_BUTTONS = [
  {
    linkText: "Tile roofs",
    linkURL: "/residential/tile-roofs",
  },
  {
    linkText: "Shingle roofs",
    linkURL: "/residential/shingle-roofs",
  },
  {
    linkText: "Low Slope & Flat Roofs",
    linkURL: "/residential/low-slope-and-flat-roofs",
  },
  {
    linkText: "Patio roofs",
    linkURL: "/residential/patio-roofs",
  },
];

const Residential = ({ data }) => {
  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="residential roof repair"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerBottomIconAlt="Icon with blue buildings"
      headerBottomIconSrc={data.BuildingsIcon.publicURL}
      headerFancyBanner={false}
      headerLinkButtonEnabled={true}
    >
      <Seo title="Residential" />

      <Box as="article" sx={{ my: [8, null, 11] }}>
        <Container>
          <ContentWithImage
            imageAlt="Man repairing a roof."
            imageURL={data.ResidentialServices.childImageSharp.gatsbyImageData}
            linkArr={LINK_BUTTONS}
            linkArrTitle="roof types we service"
            linksEnabled={true}
            titleText="Residential Roof Repair Services"
          >
            <p>
              No matter how well your roof is constructed, it will need repairs
              from time to time. Every roof type has its own specific strengths,
              weaknesses, and needs: some are more prone to flaking and rotting,
              while others are vulnerable to sun damage or cracks. Whatever type
              of roof you have, our repair specialists know how to find its
              vulnerabilities and quickly fix any issues.
            </p>

            <p>
              The good news about roof repairs is that they are much less
              expensive than a replacement. Proper roof service and maintenance
              can extend your roof’s lifespan by years.
            </p>

            <Heading as="h3" variant="styles.h3" sx={{ mt: 6 }}>
              Types of Residential Damage
            </Heading>

            <p>
              When you notice a leak in your home, it doesn’t necessarily mean
              you need a new roof. However, it does signal that you need to get
              it fixed as soon as possible. Left unaddressed, even a tiny leak
              can cause serious structural damage to not only your roof, but the
              entire house.
            </p>

            <p>
              If you discover a leak or think your roof may be damaged, call us
              right away. In one service call, we will find the problem, repair
              it if possible, and help you understand what your roof needs to go
              the distance.
            </p>

            <Heading as="h3" variant="styles.h3" sx={{ mt: 6 }}>
              Roof Leaks
            </Heading>

            <p>
              Loose or cracked roofing materials or even the smallest of
              punctures can lead to roof leaks. These can leave unsightly water
              spots on your ceilings, contribute to the growth of mold in your
              attic, and compromise the integrity of your roof over time. If you
              have a roof leak, you must get it taken care of right away.
            </p>

            <Heading as="h3" variant="styles.h3" sx={{ mt: 6 }}>
              Wind Damage
            </Heading>

            <p>
              Besides water damage, wind can also pose a threat to your roofing
              system. Prolonged exposure to heavy winds can cause the granules
              above the shingle to wear out--not to mention how heavy gusts can
              loosen and blow away shingles. They may even blow debris such as
              tree limbs onto your roof, causing damage.
            </p>

            <Heading as="h3" variant="styles.h3" sx={{ mt: 6 }}>
              Damage Caused By Debris
            </Heading>

            <p>
              Intense winds alone can do some significant roof damage, but the
              blowing debris it creates can also lead to missing or cracked
              shingles. Damage from heavy or high-velocity debris can cause
              damage that leads to roof leaks or even reduce portions of your
              roofing materials to piles of scrap. Our experts can help assess
              roof damage to make sure small leaks and cracks don’t become
              bigger problems.
            </p>
          </ContentWithImage>
        </Container>
      </Box>

      <HexagonList
        bannerText="roof repair made easy."
        linkText="Get Started"
        linkURL="/contact"
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    HeroImage: file(relativePath: { eq: "residential-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    BuildingsIcon: file(relativePath: { eq: "buildings.svg" }) {
      id
      publicURL
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ResidentialServices: file(relativePath: { eq: "repair-services.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default Residential;
